import React from "react"
import MapContent from "./MapContent"

const Map = () => (
    <MapContent
        googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyABqoVZ5Wb-BBtSuXd4V9SSBZ3AuSEOAEE"
        loadingElement={<div className="w-100 h-100" />}
        containerElement={<div className="w-100 h-100" />}
        mapElement={<div className="w-100 h-100" />}
    />
)

export default Map
