import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

export const Negozianti = ({ isOpen, onClose }) => (
  <Modal size="lg" isOpen={isOpen} toggle={onClose}>
    <ModalHeader toggle={onClose}>Per i negozianti</ModalHeader>
    <ModalBody className="p-0 pb-3">
      <div className="p-4" style={{ height: "75vh", overflowY: "scroll" }}>
        <div className="mb-4">
          <h6>
            Mi piacerebbe accettare il pagamento con carta anche per piccoli
            importi, ma le commissioni sono troppo alte: non mi conviene!
          </h6>
          <p>
            Probabilmente hai attivo da molto tempo un contratto per il POS con
            la tua banca, che non hai mai rinegoziato. Le commissioni sui
            pagamenti con carta erano effettivamente molto alte fino a qualche
            anno fa, ma nell'ultimo periodo la situazione è decisamente
            cambiata. Le tariffe sono calate e sono spesso previsti degli
            abbonamenti con sconti particolari sui micropagamenti.
          </p>
          <p>
            La prima cosa che ti consigliamo di fare, quindi, è quella di
            parlare con la tua banca per rinegoziare il contratto: è possibile
            che abbiano già pensato a delle soluzioni adatte alla tua attività.
          </p>
          <p>
            Esiste anche un approccio alternativo: sapevi che non è necessario
            che il POS sia fornito dalla banca su cui hai il conto corrente
            dell'attività? Esistono numerose aziende che offrono soluzioni non
            legate a un conto bancario: puoi quindi stipulare il contratto per
            il POS con loro e continuare a ricevere i pagamenti sul tuo solito
            conto, senza cambiare banca.
          </p>
          <p>
            I servizi di questo tipo sono tanti, ma riportiamo un paio di
            esempi, con la premessa che non siamo in alcun modo affiliati a
            queste società:
            <ul>
              <li>
                <a
                  href="https://sumup.it/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  SumUp
                </a>
                : non prevede nessun costo fisso di abbonamento. La commissione
                per ogni transazione è pari all'1,95% del suo importo, senza
                altre aggiunte. Si tratta di un'ottima soluzione se non ti
                capita spesso di ricevere pagamenti con carta, ma non vuoi
                essere impreparato quando capita.
              </li>
              <li>
                <a
                  href="https://www.axerve.com/punto-vendita"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Axerve
                </a>
                : offre diverse opzioni a seconda della tipologia della tua
                attività e del numero di pagamenti con carta che ricevi. La più
                interessante prevede un costo fisso mensile, variabile a seconda
                del limite di incasso e che parte da 15 €, senza però nessuna
                commissione sulla singola transazione. È ideale se ricevi tanti
                pagamenti di piccolo importo.
              </li>
            </ul>
          </p>
          <p>
            Per queste due soluzioni non è previsto il noleggio del POS: dovrai
            quindi acquistarlo, ma si tratta di una spesa che sosterrai una
            volta sola. Anche qui i costi sono ragionevoli: quelli di SumUp
            partono da 29 €.
          </p>
          <p>
            Se fra queste non trovi una soluzione che ti piace, scrivici a{" "}
            <a href="mailto:info@bergamopos.it">info@bergamopos.it</a> e
            proveremo ad aiutarti a trovarne una più adatta a te.
          </p>
          <p>
            Se invece non vuoi pagare proprio nulla, possiamo consigliarti di
            valutare{" "}
            <a
              href="https://www.satispay.com/it-it/come-funziona/"
              target="_blank"
              rel="noreferrer noopener"
            >
              Satispay
            </a>
            . L'attivazione non ha alcun costo e non richiede un'apparecchiatura
            dedicata: accetterai i pagamenti direttamente con lo smartphone o
            con il PC. Inoltre, le transazioni fino a 10 € sono per te
            completamente gratuite, mentre quelle di importo superiore prevedono
            una commissione fissa di 20 centesimi. Non c'è nessun altro costo.
          </p>
          <p>
            A nostro avviso, questa non è la soluzione ideale: i tuoi clienti
            non potranno usare la carta per pagare, ma dovranno a loro volta
            avere già installata l'app di Satispay. È però meglio di niente, e
            sicuramente farai felici i clienti più tecnologici.
          </p>
          <p>
            Sulla mappa di BergamoPOS non mostriamo le attività che accettano
            solo Satispay: nell'app è infatti già presente una lista dei negozi
            vicini dove è possibile usarla, quindi sarebbe solamente
            un'informazione duplicata. Preferiamo concentrarci sulle attività in
            cui è possibile pagare con carta.
          </p>
          <p>
            Per concludere, sappiamo che a nessuno piace pagare le commissioni.
            Ti consigliamo però di vedere il POS non solo come un costo ma
            soprattutto come un servizio che mette i tuoi clienti a loro agio e
            li invoglia a tornare da te. Noi facciamo spesso un paragone, molto
            calzante, con l'aria condizionata: sicuramente costa tanto
            installarla e tenerla accesa, e di per sé non porta introiti
            diretti. Ma senza dubbio è un elemento determinante che farà tornare
            i clienti da te anziché da un tuo concorrente che non ce l'ha. Lo
            stesso discorso si applica per il POS.
          </p>
        </div>
        <div className="mb-4">
          <h6>
            Accetto già i pagamenti con carta: come faccio a far comparire la
            mia attività sulla mappa di BergamoPOS? Quanto costa?
          </h6>
          <p>
            Comparire sulla mappa di BergamoPOS è completamente gratuito: stiamo
            cercando, in modo volontario, di creare un servizio che sia utile
            agli amanti dei pagamenti digitali come noi, e non abbiamo alcun
            fine economico nel farlo.
          </p>
          <p>
            Per essere aggiunto alla mappa ti basta compilare{" "}
            <a
              href="https://www.cognitoforms.com/BergamoPOS/SegnalaciUnattivit%C3%A0"
              target="_blank"
              rel="noreferrer noopener"
            >
              questo modulo
            </a>{" "}
            in cui ti chiediamo il nome e l'indirizzo della tua attività.
          </p>
        </div>
        <div className="mb-4">
          <h6>
            Accetto i pagamenti con carta, ma per ora preferisco farlo solo
            sopra un certo importo. Posso comunque comparire sulla mappa?
          </h6>
          <p>
            Sì, ma in questo caso l'indicatore sarà di colore giallo e verrà
            indicato nelle informazioni il limite minimo.
          </p>
        </div>
        <div>
          <h6>
            Non voglio che la mia attività sia su questo sito: come faccio a
            rimuoverla?
          </h6>
          <p className="mb-0">
            Ci dispiace per l'inconveniente. Puoi chiederci di rimuoverla in
            questo modo:
            <ul className="mb-0">
              <li>Individua la tua attività sulla mappa;</li>
              <li>
                Fai click sul link <em>"Faccelo sapere!"</em> che compare nel
                riquadro delle informazioni;
              </li>
              <li>
                Nel campo <em>"Qual è l'errore che hai trovato?"</em> scrivi che
                sei il proprietario dell'attività e che vuoi che questa sia
                rimossa dalla mappa.
              </li>
            </ul>
          </p>
        </div>
      </div>
    </ModalBody>
  </Modal>
);
