import { withScriptjs, withGoogleMap, GoogleMap } from "react-google-maps";
import React, { useEffect, useState } from "react";
import places from "../data/places";
import Place from "./Place";
import mapOptions from "./mapOptions";
import CurrentLocation from "./CurrentLocation";
import { inRange } from "lodash"

let mapCenterInitialized = false

const MapContent = () => {
  const [currentPosition, setCurrentPosition] = useState(null);
  const [mapCenter, setMapCenter] = useState({
    lat: 45.69630374351374,
    lng: 9.668258443016963,
  });
  const [opened, setOpened] = useState(null);

  useEffect(() => {
    const watchId = navigator?.geolocation.watchPosition((pos) => {
      setCurrentPosition(pos.coords);
      if (!mapCenterInitialized) {
        // Don't pan the map if the position is really outside Bergamo boundaries.
        // Bottom left limit: 45.585649258197755, 9.481341755927357
        // Upper right limit: 46.07843532859458, 10.25970318055181
        if(
          inRange(pos.coords.latitude, 45.585649258197755, 46.07843532859458)
          && inRange(pos.coords.longitude, 9.481341755927357, 10.25970318055181)
        ){
          setMapCenter({ lat: pos.coords.latitude, lng: pos.coords.longitude });
        }
        mapCenterInitialized = true
      }
    });
    return () => {
      navigator?.geolocation.clearWatch(watchId);
    };
  }, []);

  return (
    <GoogleMap
      onClick={(e) => {
        setOpened(null);
        return false;
      }}
      defaultZoom={13}
      defaultOptions={mapOptions}
      center={mapCenter}
    >
      <CurrentLocation position={currentPosition} />

      {places.map((p, k) => (
        <Place
          key={k}
          data={p}
          opened={opened === k}
          onClick={() => setOpened(k)}
          onClose={() => setOpened(null)}
        />
      ))}
    </GoogleMap>
  );
};

export default withScriptjs(withGoogleMap(MapContent));
