import React, { useRef } from "react";
import Map from "./map";
import useWindowSize from "./hooks/useWindowSize";
import CookieBanner from "./layout/CookieBanner";
import TopBar from "./layout/TopBar";
import { BrowserRouter as Router } from "react-router-dom";
import ModalsRouter from "./modals/ModalsRouter";

function App() {
  const windowSize = useWindowSize();
  const header = useRef(null);
  const map = useRef(null);

  const calculateMapHeight = () => {
    if (!header.current) {
      return 0;
    }
    return windowSize.height - header.current.offsetHeight;
  };

  const adjustMapHeight = () => {
    if (!map.current) {
      return null;
    }
    map.current.style.height = `${calculateMapHeight()}px`;
  };

  return (
    <div>
      <Router>
        <ModalsRouter />
        <div ref={header}>
          <TopBar onResize={() => adjustMapHeight()} />
        </div>
        <div ref={map} style={{ height: `${calculateMapHeight()}px` }}>
          <Map />
        </div>
        <CookieBanner />
      </Router>
    </div>
  );
}

export default App;
