import categories from "./categories";

const places = [
  {
    name: "Schiaccia",
    categories: [categories.ristoranti, categories.asporto],
    mapsLink: "https://goo.gl/maps/Kfdt1aBTGaxwHL2f9",
    address: ["Largo Bortolo Belotti, 11", "24121 Bergamo"],
    lat: 45.697084535119956,
    lng: 9.670313910498566,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: null,
  },
  {
    name: "Tabacchi in Galleria",
    categories: [categories.tabaccherie],
    mapsLink: "https://goo.gl/maps/YBAqNttGBqN6nS2AA",
    address: ["Via Borgo Palazzo", "24125 Bergamo"],
    lat: 45.69203672183551,
    lng: 9.695374124568575,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes:
      "Anche il distributore esterno h24 accetta i pagamenti con carta.",
  },
  {
    name: "Cafeteria",
    categories: [categories.ristoranti, categories.bar],
    mapsLink: "https://g.page/cafeteriatreviolo?share",
    address: ["Viale Europa, 32", "24048 Treviolo (BG)"],
    lat: 45.67715080201881,
    lng: 9.635033241018332,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: null,
  },
  {
    name: "Caffé Latte",
    categories: [categories.bar],
    mapsLink: "https://goo.gl/maps/tYkR1jEWXSRwmUsz9",
    address: ["Via Dei Piazzoli, 1", "24040 Suisio (BG)"],
    lat: 45.66042763557632,
    lng: 9.503924439784141,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: null,
  },
  {
    name: "Vanilla Food",
    categories: [categories.bar, categories.pasticcerie],
    mapsLink: "https://goo.gl/maps/sNEZod499BzL4uQ99",
    address: ["Via Veneto, 42/44", "24041 Brembate (BG)"],
    lat: 45.61376619769607,
    lng: 9.546193926290167,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: null,
  },
  {
    name: "Caffè del Largo",
    categories: [categories.bar],
    mapsLink: "https://goo.gl/maps/fzGgFt5S1fdcjBySA",
    address: ["Largo Bortolo Belotti, 26", "24121 Bergamo (BG)"],
    lat: 45.69678959293867,
    lng: 9.67029355446677,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: null,
  },
  {
    name: "Marchesi Stefania",
    categories: [categories.tabaccherie],
    mapsLink: "https://goo.gl/maps/JvZFZM1zXNTZx3Au7",
    address: ["Via Galileo Galilei, 31", "24035 Curno (BG)"],
    lat: 45.690530124497904,
    lng: 9.612201114879232,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: null,
  },
  {
    name: "Kung Food Lin",
    categories: [categories.ristoranti],
    mapsLink: "https://goo.gl/maps/u7mnVRuwwmfm3CPR6",
    address: ["Via Celadina, 193", "24125 Bergamo (BG)"],
    lat: 45.69362568587721,
    lng: 9.71211634853191,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes:
      "Per la consegna a domicilio non accetta la carta, ma accetta Satispay o PayPal.",
  },
  {
    name: "Gran Bazar Di Wang Xiaolei & C. S.A.S.",
    categories: [categories.empori],
    mapsLink: "https://goo.gl/maps/WHYYxxw6RRUwsj7D8",
    address: ["Via Vittorio Veneto, 2", "24040 Bonate Sotto (BG)"],
    lat: 45.67304680563811,
    lng: 9.562552855792827,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: null,
  },
  {
    name: "Farmacia Lucini snc del Dr. Marco Lucini & C.",
    categories: [categories.farmacie],
    mapsLink: "https://goo.gl/maps/ZLMNAPhk78jdWCfz7",
    address: ["Via Vittorio Veneto, 52", "24040 Bonate Sotto (BG)"],
    lat: 45.66683484394691,
    lng: 9.558534896156972,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: null,
  },
  {
    name: "Farmacia Villa d'Almè",
    categories: [categories.farmacie],
    mapsLink: "https://goo.gl/maps/aHVFfcAtAnCP1hHEA",
    address: ["Via F.lli Calvi, 8", "24018 Villa d'Almè (BG)"],
    lat: 45.75062218038644,
    lng: 9.612201363991215,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: null,
  },
  {
    name: "Alimentari Rinaldi Teodolinda",
    categories: [categories.alimentari, categories.articolicasa],
    mapsLink: "https://goo.gl/maps/WzbF5ay7HBvznGTQ9",
    address: ["Via Poscante Centro, 11", "24019 Zogno (BG)"],
    lat: 45.78367417265248,
    lng: 9.688075529537462,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: null,
  },
  {
    name: "La Pasqualina",
    categories: [categories.pasticcerie, categories.gelaterie],
    mapsLink: "https://goo.gl/maps/UJcJSFYaP9eKywjT8",
    address: ["Via Papa Giovanni XXIII, 39", "24030 Almenno San Bartolomeo (BG)"],
    lat: 45.73800566205724,
    lng: 9.581662022840804,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: null,
  },
  {
    name: "Fabric - Into the Woods",
    categories: [categories.bar, categories.ristoranti],
    mapsLink: "https://goo.gl/maps/64AvJ3vS5uyHoswj9",
    address: ["Via Ugo Foscolo, 10", "24020 Torre Boldone (BG)"],
    lat: 45.71294742681664,
    lng: 9.704130912517034,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: "I camerieri hanno sempre il POS con loro ed è quindi possibile pagare l'ordine con carta senza alzarsi dal tavolo.",
  },
  {
    name: "Farmacia Campagnola",
    categories: [categories.farmacie],
    mapsLink: "https://goo.gl/maps/6E24AwYCDhcC6sQV6",
    address: ["Via Campagnola, 25", "24126 Bergamo (BG)"],
    lat: 45.67907554515285,
    lng: 9.676860758451397,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: null,
  },
  {
    name: "Tabaccheria Conca Verde",
    categories: [categories.tabaccherie],
    mapsLink: "https://goo.gl/maps/mKQi9oVurtY9EqFz8",
    address: ["Via Gioachino Rossini, 2", "24129 Bergamo (BG)"],
    lat: 45.693962634756595,
    lng: 9.628216966809456,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: "Il pagamento con carta non è accettato per gratta e vinci e ricariche telefoniche.",
  },
  {
    name: "Al Chiosco",
    categories: [categories.bar, categories.ristoranti],
    mapsLink: "https://g.page/alchioscobrusaporto",
    address: ["Via Cattanea", "24060 Brusaporto (BG)"],
    lat: 45.66900683510723,
    lng: 9.764377791348196,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: null,
  },
  {
    name: "Forneria Milesi",
    categories: [categories.bar],
    mapsLink: "https://goo.gl/maps/J5oeedfeFE2UdXbt6",
    address: ["Via Cesare Battisti", "24060 Brusaporto (BG)"],
    lat: 45.671054071703146,
    lng: 9.76136061183726,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: null,
  },
  {
    name: "Panificio F.lli Persico",
    categories: [categories.alimentari],
    mapsLink: "https://goo.gl/maps/9rW4U84WRVif6XrL9",
    address: ["Via Giovanni XXIII, 12", "24024 Gandino (BG)"],
    lat: 45.811317658400974,
    lng: 9.902620435153542,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: null,
  },
  {
    name: "La Spiga D'Oro",
    categories: [categories.alimentari],
    mapsLink: "https://goo.gl/maps/bNspxRiB1HFXrnH6A",
    address: ["Viale Rimembranze, 8", "24024 Gandino (BG)"],
    lat: 45.80916534850167,
    lng: 9.898823427629802,
    minAmount: 0,
    insertedBy: null,
    insertedByLink: null,
    notes: null,
  },
];

export default places;
