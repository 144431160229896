import CookieConsent from "react-cookie-consent";

const CookieBanner = () => (
  <CookieConsent
    location="bottom"
    buttonText="Va bene"
    cookieName="bergamoPosCookieAccepted"
  >
    Questo sito utilizza cookie tecnici e di profilazione per memorizzare le tue
    preferenze e per scopi statistici.
    <span className="ml-2" style={{ fontSize: "10px" }}>
      <a
        href="https://www.iubenda.com/privacy-policy/20146063"
        target="_blank"
        rel="noopener noreferrer"
        className="text-white"
      >
        Leggi la privacy policy
      </a>
    </span>
  </CookieConsent>
);

export default CookieBanner;
