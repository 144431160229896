import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import { Link } from "react-router-dom";

export const ComeFunziona = ({ isOpen, onClose }) => (
  <Modal size="lg" isOpen={isOpen} toggle={onClose}>
    <ModalHeader toggle={onClose}>Come funziona</ModalHeader>
    <ModalBody className="p-0 pb-3">
      <div className="p-4" style={{ height: "75vh", overflowY: "scroll" }}>
        <div className="mb-4">
          <h6>Come è nato BergamoPOS?</h6>
          <p>
            Siamo un gruppo di giovani amici poco abituati all'uso del contante,
            a cui capita spesso di girare nella bergamasca senza nemmeno qualche
            moneta.
          </p>
          <p>
            Nel momento in cui vogliamo prenderci un caffé, una birra o un
            pacchetto di sigarette, si pone sempre la solita questione:{" "}
            <em>Dove andiamo? Prenderanno la carta?</em>
          </p>
          <p>
            Nel 2015 Bergamo è stata capofila del progetto Cashless City, un
            vero successo: in pochi mesi, più del 10% dei commercianti hanno
            installato il POS. Purtroppo, però, chi vive a Bergamo sa che
            l'obiettivo di potersi muovere senza contanti è ben lontano
            dall'essere raggiunto: per i piccoli pagamenti, è davvero difficile
            trovare qualcuno che accetti di essere pagato con carta.
          </p>
          <p>
            Dopo tanto tempo perso nel girare fra bar e tabaccai per chiedere se
            accettassero la carta, abbiamo pensato che probabilmente non siamo i
            soli. Da qui l'idea di unire gli sforzi e creare, finalmente, una
            mappa che possa aiutarci.
          </p>
          <p>
            Qui non troverai indicati supermercati, distributori di benzina o
            negozi affiliati a grandi catene: in questi casi, fortunatamente, il
            fatto che la carta sia accettata è piuttosto scontato. Il nostro
            intento è invece quello di creare una guida utile per quelle
            categorie di attività dove il dubbio è legittimo.
          </p>
        </div>
        <div className="mb-4">
          <h6>Quante storie! Non potreste semplicemente prelevare?</h6>
          <p>
            Si tratta di un'abitudine che non ci appartiene, per diversi motivi:
            <ul>
              <li>
                Gli stipendi vengono accreditati sul conto corrente: che senso
                ha far fare ai soldi altri passaggi?
              </li>
              <li>
                I contanti sono più facili da perdere e possono essere oggetto
                di furto. A differenza delle carte, che se smarrite o rubate
                possono essere bloccate e sono quasi inutili senza PIN, non c'è
                modo di riavere indietro il valore dei contanti.
              </li>
              <li>
                Secondo una ricerca dell'Osservatorio Mobile Payment & Commerce
                del Politecnico di Milano, i costi che lo Stato sostiene ogni
                anno per produrre, trasportare e gestire i contanti ammontano a
                10 miliardi di euro. Sono soldi che paghiamo noi cittadini con
                le nostre tasse!
              </li>
            </ul>
          </p>
        </div>
        <div className="mb-4">
          <h6>
            Il mio negoziante di fiducia dice che non accetta i pagamenti con
            carta per piccoli importi perché le commissioni sono troppo alte: ha
            ragione?
          </h6>
          <p>
            Probabilmente questo negoziante ha stipulato un contratto con la sua
            banca molto tempo fa, che non ha mai rinegoziato. Fino a qualche
            anno fa, in effetti, quello delle commissioni era un grosso
            problema, ma oggi ci sono tante soluzioni con commissioni basse e
            agevolazioni per i piccoli pagamenti. Potresti consigliargli di
            leggere la nostra{" "}
            <Link to="/negozianti">sezione per i negozianti</Link>, dove
            affrontiamo nel dettaglio questo argomento!
          </p>
        </div>
        <div className="mb-4">
          <h6>
            Questo servizio sarà presto inutile: per legge tutte le attività
            dovranno accettare la carta!
          </h6>
          <p>
            Speriamo! Quando davvero sarà possibile spostarsi senza contanti non
            avendo paura di non poter usufruire di qualche servizio, saremo
            felicissimi di non aver più bisogno di BergamoPOS.
          </p>
          <p>
            Secondo noi, però, questo processo sarà più lungo di quanto
            immagini: certi cambiamenti non avvengono da un giorno all'altro{" "}
            <em>per legge</em>. Basta pensare ai distributori automatici di
            snack, pochissimi dei quali ancora accettano il pagamento con carta,
            o a quelli dell'acqua potabile.
          </p>
          <p>
            Siamo inoltre convinti che, nonostante l'obbligo, qualche
            commerciante cercherà il modo per evitare di accettare la carta:
            magari con la classica scusa del POS non funzionante, o provando ad
            imporre una maggiorazione sul costo in caso di pagamento
            elettronico.
          </p>
          <p>
            Per questo, anche se siamo i primi che non lo vorrebbero, temiamo
            che BergamoPOS sarà utile ancora per un bel po' di tempo.
          </p>
        </div>
        <div className="mb-4">
          <h6>Voi quanto guadagnate da questo sito?</h6>
          <p>
            Assolutamente nulla: si tratta di un'attività completamente
            volontaria. Al momento siamo in grado di gestire i costi per
            mantenere il sito attivo; se la situazione dovesse cambiare,
            potremmo iniziare ad accettare delle donazioni per sostenere i
            costi.
          </p>
        </div>
        <div className="mb-4">
          <h6>Mi piace! Posso contribuire?</h6>
          <p>
            In questo momento ciò di cui abbiamo più bisogno sono segnalazioni
            delle attività che accettano volentieri i pagamenti con carta, da
            aggiungere alla mappa. Se ne conosci qualcuno, per favore{" "}
            <a
              href="https://www.cognitoforms.com/BergamoPOS/SegnalaciUnattivit%C3%A0"
              target="_blank"
              rel="noopener noreferrer"
            >
              faccelo sapere
            </a>
            ! Se invece vuoi aiutarci nello sviluppo, portare la stessa
            piattaforma nella tua provincia, o incontarci per una birra,
            scrivici a{" "}
            <a href="mailto:info@bergamopos.it">info@bergamopos.it</a> e sarà un
            piacere approfondire.
          </p>
        </div>
        <div className="mb-2">
          <h6>Credits</h6>
          <ul>
            <li>
              <a
                href="https://www.alerinaldi.it"
                target="_blank"
                rel="noopener noreferrer"
              >
                AleRinaldi.it
              </a>
              : sviluppo e gestione del sito web
            </li>
            <li>
              Jose Dean from the Noun Project:{" "}
              <a
                href="https://thenounproject.com/term/card-chip/2332260/"
                target="_blank"
                rel="noopener noreferrer"
              >
                disegno del chip
              </a>{" "}
              usato nel logo
            </li>
          </ul>
        </div>
        <div>
          Vuoi leggere altre cose noiose? C'è la nostra{" "}
          <a
            href="https://www.iubenda.com/privacy-policy/20146063"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </a>
          .
        </div>
      </div>
    </ModalBody>
  </Modal>
);
