import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ComeFunziona } from "./ComeFunziona";
import {Negozianti} from "./Negozianti";

const ModalsRouter = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const closeModal = () => history.push("/");

  return (
    <>
      <ComeFunziona
        isOpen={pathname === "/come-funziona"}
        onClose={closeModal}
      />
      <Negozianti
        isOpen={pathname === "/negozianti"}
        onClose={closeModal}
      />
    </>
  );
};

export default ModalsRouter;
