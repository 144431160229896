import React from "react";
import { Marker, Circle } from "react-google-maps";

const CurrentLocation = ({ position }) => {
  if (!position) {
    return null;
  }
  return (
    <>
      <Marker
        icon={{
          path: window.google.maps.SymbolPath.CIRCLE,
          scale: 7,
          fillOpacity: 1,
          strokeWeight: 2,
          fillColor: "#4185f4",
          strokeColor: "#ffffff",
        }}
        position={{ lat: position.latitude, lng: position.longitude }}
        clickable={false}
      />
      <Circle
        center={{ lat: position.latitude, lng: position.longitude }}
        radius={position.accuracy}
        options={{
          clickable: false,
          fillColor: "#4185f4",
          fillOpacity: 0.1,
          strokeWeight: 0,
        }}
      />
    </>
  );
};

export default CurrentLocation;
