import React from "react";
import {
  Collapse,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarText,
  NavbarToggler,
  NavLink,
} from "reactstrap";
import { useEffect, useState } from "react";
import { withResizeDetector } from "react-resize-detector";
import logo from "./navbar-logo.png";
import "./TopBar.css";
import { Link } from "react-router-dom";

const TopBar = ({ width, height, onResize }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  useEffect(() => onResize(width, height), [width, height, onResize]);
  const toggleDropdown = () => setDropdownOpen(!dropdownOpen);

  return (
    <Navbar expand="md" dark className="navbar">
      <NavbarBrand>
        <img src={logo} alt="BergamoPOS" className="navbar-logo" />{" "}
        <span className="align-text-top ml-1">BergamoPOS</span>
      </NavbarBrand>
      <NavbarText className="ml-4 d-none d-lg-block">
        Paga con carta a Bergamo
      </NavbarText>

      <NavbarToggler onClick={toggleDropdown} />
      <Collapse isOpen={dropdownOpen} navbar>
        <Nav className="ml-auto text-right" navbar>
          <NavLink
            tag={Link}
            to="/come-funziona"
            onClick={toggleDropdown}
            className="float-right text-white mr-4"
          >
            Come funziona
          </NavLink>
          <NavLink
            tag={Link}
            to="/negozianti"
            onClick={toggleDropdown}
            className="float-right text-white mr-4"
          >
            Per i negozianti
          </NavLink>
          <NavLink
            className="text-white mr-4"
            href="https://www.cognitoforms.com/BergamoPOS/SegnalaciUnattivit%C3%A0"
            target="_blank"
            onClick={toggleDropdown}
            rel="noopener noreferrer"
          >
            Segnalaci un'attività
          </NavLink>
        </Nav>
      </Collapse>
    </Navbar>
  );
};

export default withResizeDetector(TopBar);
