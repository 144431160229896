import React from "react";
import { Marker, InfoWindow } from "react-google-maps";
import GreenDot from "./images/green-dot.png";
import YellowDot from "./images/yellow-dot.png";
import { Alert, Button } from "reactstrap";

const Place = ({ data, opened, onClose, onClick }) => {
  const placeLink =
    data.mapsLink || `http://www.google.com/maps/place/${data.lat},${data.lng}`;

  const formData = {
    Name: data.name,
    Address: data.address.join("\n"),
  };
  const encodedFormData = encodeURIComponent(JSON.stringify(formData));

  return (
    <Marker
      onClick={onClick}
      icon={data.minAmount > 0 ? YellowDot : GreenDot}
      position={{ lat: data.lat, lng: data.lng }}
    >
      {opened && (
        <InfoWindow onCloseClick={onClose}>
          <div>
            <h5 className="font-weight-bold mb-0">{data.name}</h5>
            <span className="text-muted mt-0">
              {data.categories.map((c) => c.singular).join(", ")}
            </span>
            <div className="mt-2 my-3">
              {data.address.map((addressPiece) => (
                <p className="mb-0">{addressPiece}</p>
              ))}
            </div>
            {data.minAmount > 0 ? (
              <Alert color="warning" className="text-center mb-1">
                Accetta pagamenti con carta per un importo di almeno{" "}
                {data.minAmount} €.
              </Alert>
            ) : (
              <Alert color="success" className="text-center mb-1">
                Accetta pagamenti con carta per qualunque importo!
              </Alert>
            )}
            <p className="font-italic mb-3 mt-0">
              Non è vero?{" "}
              <a
                  href={`https://www.cognitoforms.com/BergamoPOS/SegnalaciDatiErrati?entry=${encodedFormData}`}
                  target="_blank"
                  rel="noopener noreferrer"
              >
                Faccelo sapere!
              </a>
            </p>
            {data.notes && (
              <p>
                <span className="font-weight-bold">Note:</span>
                <br />
                {data.notes}
              </p>
            )}
            <div className="w-100 text-center mb-0">
              <a href={placeLink} target="_blank" rel="noreferrer noopener">
                <Button color="primary" className="w-100">
                  Mostra su Google Maps
                </Button>
              </a>
            </div>
          </div>
        </InfoWindow>
      )}
    </Marker>
  );
};

export default Place;
