const categories = {
  ristoranti: {
    singular: "Ristorante",
    plural: "Ristoranti",
  },
  tabaccherie: {
    singular: "Tabaccheria",
    plural: "Tabaccherie",
  },
  bar: {
    singular: "Bar",
    plural: "Bar",
  },
  asporto: {
    singular: "Cibo d'asporto",
    plural: "Cibo d'asporto",
  },
  pasticcerie: {
    singular: "Pasticceria",
    plural: "Pasticcerie",
  },
  empori: {
    singular: "Emporio",
    plural: "Empori",
  },
  farmacie: {
    singular: "Farmacia",
    plural: "Farmacie",
  },
  alimentari: {
    singular: "Alimentari",
    plural: "Alimentari",
  },
  articolicasa: {
    singular: "Articoli per la casa",
    plural: "Articoli per la casa",
  },
  gelaterie: {
    singular: "Gelateria",
    plural: "Gelaterie",
  },
};

export default categories;
